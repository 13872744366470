export type PastorListItem = {
    label: string;
    value: string;
};

type PastorListType = {
    [key: string]: PastorListItem;
};

export const PastorList: PastorListType = {
    OUTSIDE_GROUP: {
        label: 'Вне малой группы',
        value: 'OUTSIDE_GROUP',
    },
    OUTSIDE_MINISTRY: {
        label: 'Не нашли служение',
        value: 'OUTSIDE_MINISTRY',
    },
    EXCOMMUNICATED: {
        label: 'Отлучены',
        value: 'EXCOMMUNICATED',
    },
    NOT_CONFIRM_MEMBERSHIP: {
        label: 'Не подтвердили завет членства',
        value: 'NOT_CONFIRM_MEMBERSHIP',
    },
    TEMPORARY_SUSPENSION_MEMBERSHIP: {
        label: 'Временно выбыли',
        value: 'TEMPORARY_SUSPENSION_MEMBERSHIP',
    },
    NOT_DONATE_RECENTLY: {
        label: 'Проблемы с финансами',
        value: 'NOT_DONATE_RECENTLY',
    },
    MEMBERS_FILTER: {
        label: 'Членство',
        value: 'MEMBERS_FILTER',
    },
    QUEUE_FOR_INTERVIEW: {
        label: 'Очередь на собеседование',
        value: 'QUEUE_FOR_INTERVIEW',
    },
    READY_FOR_BAPTISM: {
        label: 'Готовы к крещению',
        value: 'READY_FOR_BAPTISM',
    },
    READY_FOR_TESTIMONY: {
        label: 'Готовы к рассказу свидетельства',
        value: 'READY_FOR_TESTIMONY',
    },
};

export const pastorListOptions = Object.values(PastorList);
